<template lang="pug">
  .row
    .col-md-12
      .component-card
        .form-group
          v-tabs(
            colored
            :options="options")
      .component-card
        div.mb-3
          v-editable(@change="(value) => { editable.text1 = value }")
            h1.title {{ editable.text1 }}
        div.mb-2
          v-editable(@change="(value) => { editable.text2 = value }")
            h2.subtitle {{ editable.text2 }}
        div.mb-3
          v-editable(@change="(value) => { editable.text3 = value }")
            p {{ editable.text3 }}
        v-chip.ma-2(
          v-for="(item, index) in options"
          :key="index")
          v-editable(@change="(value) => { item.title = value }")
            p {{ item.title }}

</template>

<script>
export default {
  name: 'VSelectDemo',

  data: () => ({
    options: [
      {
        title: 'Red',
        color: '#f44336'
      },
      {
        title: 'Green',
        color: '#4CAF50'
      },
      {
        title: 'Purple',
        color: '#9C27B0'
      },
      {
        title: 'Blue',
        color: '#3F51B5'
      }
    ],
    editable: {
      text1: 'VEditable title',
      text2: 'Editable subtitle',
      text3: 'Lorem ipsum dolor sita amet'
    }
  })
}
</script>

<style>
</style>
